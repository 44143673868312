var waypointHelper = (function () {
	var waypoint = $('.overview');
	

	function waypointHelper() {
		if(!waypoint.length) {
			return;
		}
		init();

	}

	function init() {
		// sticky
		

		var waypoint = new Waypoint({
			element: document.getElementById('breadcrumb'),
			handler: function(direction) {
		    	$('.breadcrumb-component, .overview, header.social-header').toggleClass('sticky');

		  	}
		})

		var snapper = new Waypoint({
  			element: document.getElementById('snapper'),

  			handler: function(direction) {

  				if (direction == 'up') {
  					// console.log('UPP');
  				}
  				else if (direction == 'down') {
					$('html,body').animate({scrollTop: $('.snapper').offset().top}, 300);
  				}
    			// console.log(this.element.id + ' triggers at ' + this.triggerPoint + direction);
  			},
  			offset: '450'

		})



	}

	function setEventListeners() {
		
	}


	return waypointHelper;

})();

module.exports = waypointHelper;