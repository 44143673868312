var masonry = require('masonry-layout');

var masonryHelper = (function () {
	var $grid = $('.grid-items');
	var grid = $grid[0];
	var itemSelector = '.module-list-item';

	function masonryHelper() {
		if(!$grid.length) {
			return;
		}
		init();
	}

	function init() {
		initPackeryGrid();
	}

	function initPackeryGrid() {
		var msnry = new masonry(grid, {
			itemSelector: 'article',
			columnWidth: 'article',
			percentPosition: false,
			transitionDuration: 0,
			gutter: 0,
			isResizable: true
		});

		//msnry.on('layoutComplete', fadeInGrid);
		msnry.layout();
	}

	// function fadeInGrid() {
	// 	$grid.addClass('content-feed-module--visible');
	// }

	return masonryHelper;

})();

module.exports = masonryHelper;