var slick = require('./../vendors/slick-carousel');

var CarouselHelper = (function () {
	var carousel = $('.carousel');
	var slickInstance = $('.carousel')[0];
	var twitterCarousel = $('.twitter-feed');
	

	function CarouselHelper() {
		init();	
		
	}

	var init = function() {
		if(carousel.length)  {
			carouselInit(); 
		} 
		else if(twitterCarousel.length) {
			twitterCarouselInit()
		}

		
		carouselEvents();

		
		
	};

	var carouselInit = function() {
		carousel.slick({
			swipe: false,
			fade: true,
			adaptiveHeight: true
		});

		slickInstance.slick.$nextArrow.off('click.slick');
		slickInstance.slick.$prevArrow.off('click.slick');
		
	};
	var twitterCarouselInit = function() {
		setTimeout(function(){
            twitterCarousel.slick({
                fade: true,
                autoplay: true,
                arrows: false,
                autoplaySpeed: 5000
            });
            $('.content-twitter').removeClass('hidden');

        }, 2200);	
	};

	var carouselEvents = function() {
		

		// On before slide change
		$(carousel).on('beforeChange', function(event, slick, currentSlide, nextSlide){  
						
		});

		$(carousel).on('afterChange', function(event, slick, currentSlide){
			// console.log("test");
		});
		$(carousel).on('setPosition', function(event, slick){
  			// $('.recipes-image-slice, .recipes-content').removeClass('fadeout');
		});
		
		

		$( ".carousel .slick-next" ).on( "click", function() {
  			$('.recipes-image-slice, .recipes-content').addClass('fadeout');
  			setTimeout(function() {
  				$(carousel).slick('slickNext');
  				
  				// $('.recipes-image-slice').removeClass('initAnim');
  				$('.recipes-image-slice').addClass('initAnim');
  				$('.recipes-image-slice, .recipes-content').removeClass('fadeout');
  				
  			}, 2000);
  			
		});
		$( ".carousel .slick-prev" ).on( "click", function() {
  			$('.recipes-image-slice, .recipes-content').addClass('fadeout');
  			
  			setTimeout(function() {
  				$(carousel).slick('slickPrev');
  				
  				$('.recipes-image-slice').addClass('initAnim');
  				$('.recipes-image-slice, .recipes-content').removeClass('fadeout');
  				
  				
  			}, 2000);
  			
		});
		
	}

	return CarouselHelper;

})();

module.exports = CarouselHelper;


































