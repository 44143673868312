/*
|--------------------------------------------------------------------------
| Twitter Page Feed Helper
|--------------------------------------------------------------------------
|
*/

var $ = require('jquery');

var Twitter = {

    /* Twitter wrapper elem */
    elem: document.querySelector('.twitter-feed'),
    
    /* Twitter data vars */
    posts: null,
    profile_image: null,

	/* Initializer */
	init: function() {
        if (Twitter.elem){
            Twitter.getTweets();
        }
        else{
            console.log('no twitter');
        }
		
	},

    /* 
		Simple ajax call to php script to get Twitter page data
		Action happens in PHP script
	*/
    getTweets: function() {
    	$.ajax({
			url: "/twitter.php"
		}).done(function(res) {
			Twitter.posts = res;
            // Show tweets
            Twitter.showTweets();
		}).error(function(err) {
            console.log(err);
        });
    },

    /* Show posts */
    showTweets: function() {
    	Twitter.posts.forEach(function(el, i) {
    		// // Create wrapper element
    		 var li = document.createElement('div');
    		
    		// Create message wrapper
    		var messageWrapper = document.createElement('div');
    		messageWrapper.classList.add('message');
    		var messageEl = el.text;

    		// Append message to element
    		messageWrapper.innerHTML = "<p>" + messageEl + "</p>";

    		// Append message
    		li.appendChild(messageWrapper);

    		// Append li element to ul.Twitter-feed
    		Twitter.elem.appendChild(li);
    	});
    }

};

module.exports = Twitter;