'use strict';
global.$ = require('jquery');
var helpers = require('./modules/helpers');
// var masonry = require('masonry-layout');
var carouselHelper = require('./modules/carouselHelper');
var masonryHelper = require('./modules/masonryHelper');
var waypoints = require('waypoints/lib/noframework.waypoints');
var waypointsshortcut = require('waypoints/lib/shortcuts/sticky');
var waypointHelper = require('./modules/waypointHelper');
var modalHelper = require('./modules/modalHelper');
var twitterHelper = require('./modules/twitterHelper');
require('viewport-units-buggyfill').init();
// require('vendors/backgroundVideo');



var app = {
	classes: {
		carouselHelper: null,
		masonryHelper: null,
		waypointHelper: null,
    twitterHelper: null,
		modalHelper: null,
	},
    init: function() {
      app.classes.carouselHelper = new carouselHelper();
      app.classes.masonryHelper = new masonryHelper();
      app.classes.waypointHelper = new waypointHelper();
      app.classes.modalHelper = new modalHelper();

      // Initialize Twitter
      twitterHelper.init();


      // animating hover
      $(".component-inner--gray").hover(
        function() {
        	$('.component-inner--gray img').attr("src", "/images/BrodinstitutetBreadLoaf.gif");
        },
        function() {
        	$('.component-inner--gray img').attr("src", "/images/BrodinstitutetBreadLoaf.gif");
        }
    	);
			// ARROW FADE
			$(window).scroll(function() {
	    		var scroll = $(window).scrollTop();
	        	$('.scroll-arrow').css({'opacity':(( 180-scroll )/400)+0.4});
						$('.start-image').css({'opacity':(( 180-scroll )/400)+0.8});
			});
			// SCROLLTO
			$('a.js-scrollto[href^="#"]').click(function() {
				$('html,body').animate({ scrollTop: $(this.hash).offset().top}, 600);
				// console.log('click på static');
				return false;
				e.preventDefault();
			});

			// SCROLLTO
			$('a.js-slidearrow[href^="#"]').click(function() {
				// $('html,body').animate({ scrollTop: $(this.hash).offset().top}, 600);
				// console.log('click på static');
				if ($('html').hasClass('fp-enabled')){
					fullpage.moveTo('section2', 2);
				}
				else {
					$('html,body').animate({ scrollTop: $(this.hash).offset().top}, 600);
				}

				return false;
				e.preventDefault();
			});
  }
};

$(window).load(function() {
	var viewportwidth = document.body.clientWidth;
	$('#loader').css({ opacity: 0 });

	setTimeout(function(){
			$('#loader').remove();
	}, 2400);

	// initialize slider
	if ($('.mainscroll').length && viewportwidth > 767 ) {
		fullpage.initialize('.mainscroll', {
			navigation: true,
			navigationPosition: 'right',
			navigationTooltips: ['', '', '', '', ''],
			anchors:['start', 'section2', 'section3', 'section4'],
			css3:true,
			sectionSelector: '.slider-section'
		});
	}

});

$( window ).resize(function() {
	var viewportwidth = document.body.clientWidth;

	setTimeout(function(){
	$('#main-video').backgroundVideo({
				$videoWrap: $('#video-wrap'),
							pauseVideoOnViewLoss: false
					});
	}, 1000);



	if ($('.mainscroll').length && viewportwidth > 767 && !$( 'html' ).hasClass( 'fp-enabled' ) ) {




		console.log('resize!');
		fullpage.initialize('.mainscroll', {
			navigation: true,
			navigationPosition: 'right',
			navigationTooltips: ['', '', '', '', ''],
			anchors:['start', 'section2', 'section3', 'section4'],
			css3:true,
			sectionSelector: '.slider-section'
		});
	}
});

$(document).ready(function() {
	setTimeout(function(){
	$('#main-video').backgroundVideo({
				$videoWrap: $('#video-wrap'),
							pauseVideoOnViewLoss: false
					});
	}, 1000);
});

app.init();
