
var modalHelper = (function () {

	function modalHelper() {
		init();
	}

	function init() {

		// MODAL FUNCTIONALITY
        function openModal() {
			$('html, body').addClass('modal-open');

			// if(app.iOS) {
			// 	$('body').addClass('ios');
			// }

			$('.modal').addClass("open");
			$('.modalOverlay, .modalClose').addClass('open');

			// if(app.hasScrollbar) {
			// 	$('body').addClass('hasScrollbar');
			// }
		}

		function closeModal() {
			$('html, body').removeClass('modal-open');
			$('body').removeClass('ios');

			$('body').removeClass('hasScrollbar');

			$('.modal').removeClass('open');
			$('.modalOverlay, .modalClose').removeClass('open');
			$('.modal').html('');
		}
		// PLAY VIDEO
		$('.js-playvideo, .grid-video-btn').on('click', function(e) {
			openModal();
			//$('.modal').html('<iframe src="http://www.youtube.com/embed/wHy-8TAN6MU?rel=0&autoplay=1&showinfo=0&controls=0" width="100%" height="100%" frameborder="0" allowfullscreen="true">');
			$('.modal').html('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/xhCpyxZu2e4?rel=0&autoplay=1&showinfo=0&controls=1" frameborder="0" allowfullscreen></iframe>');
		});
		$('.js-playvideo2017').on('click', function(e) {
			openModal();
			//$('.modal').html('<iframe src="http://www.youtube.com/embed/wHy-8TAN6MU?rel=0&autoplay=1&showinfo=0&controls=0" width="100%" height="100%" frameborder="0" allowfullscreen="true">');
			$('.modal').html('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/FCct2K7rXKA?rel=0&autoplay=1&showinfo=0&controls=1" frameborder="0" allowfullscreen></iframe>');
		});



		// PLAY VIDEO
		$('.js-snapmodal').on('click', function(e) {
			openModal();
			$('.modal').html('<div class="snap-wrapper"><img src="images/snapimg.png"></div>');


		});


		// CLOSE MODAL
		$('.js-closeModal').on('click', function() {
			closeModal();
		});

		$('.modalOverlay').on('click', function(e) {
			if ($(e.target).hasClass("modalOverlay")) {
				closeModal();
			}
		});

	}

	return modalHelper;

})();

module.exports = modalHelper;
